*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  --color-primary: #f6aca2;
  --color-secondary: #f49b90;
  --color-tertiary: #f28b7d;
  --color-quaternary: #f07a6a;
  --color-quinary: #ee6352;

}


@font-face {
  font-family: 'TwistaOutline';
  src: local('TwistaOutline'), url('./fonts/Demo-Twista/Demo_Fonts/Fontspring-DEMO-twista-outline.otf') format('opentype');
}

body {
  background-color: #282c34;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.email-input-container {
  width: calc(12rem + 15vw);
  textAlign: 'center';
}

.main-heading {
  font-family: TwistaOutline;
  font-size: calc(2rem + 12vw);
  text-align: center;
  margin: 0;
  color: whitesmoke;
  font-weight: 400;
}

.sub-heading {
  font-family: TwistaOutline;
  font-size: calc(1rem + 4vw);
  text-align: center;
  color: whitesmoke;
  font-weight: 400;
  margin-top: -1vh;
}

.heart-logo {
  font-size:8vw;
}

.waviy {
  position: relative;
}
.waviy span {
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  animation: flip 3s infinite;
  animation-delay: calc(.3s * var(--i))
}
@keyframes flip {
  0%,80% {
    transform: rotateY(360deg)
  }
}

.text_shadows {
  text-shadow: 3px 3px 0 var(--color-secondary), 6px 6px 0 var(--color-tertiary),
  9px 9px var(--color-quaternary), 12px 12px 0 var(--color-quinary);
  /*font-family: bungee, sans-serif;*/
  font-weight: 400;
  text-transform: uppercase;
  font-size: calc(2rem + 5vw);
  text-align: center;
  margin: 0;
  color: var(--color-primary);
  animation: shadows 1.2s ease-in infinite, move 1.2s ease-in infinite;
  letter-spacing: 0.4rem;
  margin-bottom: 2vh;
}

@keyframes shadows {
  0% {
    text-shadow: none;
  }
  10% {
    text-shadow: 3px 3px 0 var(--color-secondary);
  }
  20% {
    text-shadow: 3px 3px 0 var(--color-secondary),
    6px 6px 0 var(--color-tertiary);
  }
  30% {
    text-shadow: 3px 3px 0 var(--color-secondary),
    6px 6px 0 var(--color-tertiary), 9px 9px var(--color-quaternary);
  }
  40% {
    text-shadow: 3px 3px 0 var(--color-secondary),
    6px 6px 0 var(--color-tertiary), 9px 9px var(--color-quaternary),
    12px 12px 0 var(--color-quinary);
  }
  50% {
    text-shadow: 3px 3px 0 var(--color-secondary),
    6px 6px 0 var(--color-tertiary), 9px 9px var(--color-quaternary),
    12px 12px 0 var(--color-quinary);
  }
  60% {
    text-shadow: 3px 3px 0 var(--color-secondary),
    6px 6px 0 var(--color-tertiary), 9px 9px var(--color-quaternary),
    12px 12px 0 var(--color-quinary);
  }
  70% {
    text-shadow: 3px 3px 0 var(--color-secondary),
    6px 6px 0 var(--color-tertiary), 9px 9px var(--color-quaternary);
  }
  80% {
    text-shadow: 3px 3px 0 var(--color-secondary),
    6px 6px 0 var(--color-tertiary);
  }
  90% {
    text-shadow: 3px 3px 0 var(--color-secondary);
  }
  100% {
    text-shadow: none;
  }
}

@keyframes move {
  0% {
    transform: translate(0px, 0px);
  }
  40% {
    transform: translate(-12px, -12px);
  }
  50% {
    transform: translate(-12px, -12px);
  }
  60% {
    transform: translate(-12px, -12px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}



/*.App-logo {*/
/*  height: 40vmin;*/
/*  pointer-events: none;*/
/*}*/


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
